/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import { MDXRenderer } from "gatsby-plugin-mdx"
import React from "react"
import ReactDisqusComments from 'react-disqus-comments';
import Layout from "./layout"
import ItemTags from "./item-tags"
import SEO from "./seo"
import Img from "gatsby-image"

type Props = {
  pageContext
}

type PostProps = {
  data: {
    post: {
      id: string
      slug: string
      title: string
      date: string
      tags?: {
        name: string
        slug: string
      }[]
      description?: string
      postedLink?: string
      body: string
      excerpt: string
      timeToRead?: number
      banner?: {
        childImageSharp: {
          fluid
        }
      }
    }
  },
}

const px = [`32px`, `16px`, `8px`, `4px`]
const shadow = px.map((v) => `rgba(0, 0, 0, 0.15) 0px ${v} ${v} 0px`)

const Post = ({ data: { post }}: PostProps) => {

  // console.log('post in the component:', post);
  
  return (
    <Layout>
      <SEO
        title={post.title}
        description={post.description ? post.description : post.excerpt}
        image={post.banner ? post.banner.childImageSharp.fluid : undefined}
        pathname={post.slug}
      />
      <section sx={{
        img: {
          objectFit: 'cover',
          objectPosition: '50% 30%',
          borderRadius: '5px',
          width: '100%',
          height: '200px'
        },
        iframe: {
          objectFit: 'contain',
          width: '100%',
          height: ['360px', '430px', '540px']
        },
      }}
      >
        {post.banner && <Img fluid={post.banner.childImageSharp.fluid} />}
      </section>
      <Styled.h2>{post.title}</Styled.h2>
      <p sx={{ color: `secondary`, mt: 3, a: { color: `secondary` }, fontSize: [1, 1, 2] }}>
        <time>{post.date}</time>
        {post.tags && (
          <React.Fragment>
            {` — `}
            <ItemTags tags={post.tags} />
          </React.Fragment>
        )}
        {post.timeToRead && ` — `}
        {post.timeToRead && <span>{post.timeToRead} min read</span>}
      </p>
      <section sx={{
        my: 5,
        ".gatsby-resp-image-wrapper": {
          my: [4, 4, 5],
          boxShadow: shadow.join(`, `)
        },
        img: {
          maxWidth: '100%'
        }
      }}
      >
        <MDXRenderer>{post.body}</MDXRenderer>
      </section>
    </Layout>
  )
}

export default ({ pageContext }: Props) => {
  const { post } = pageContext

  return <Post data={{ post }} />
}
